import { motion } from "framer-motion";
import dynamic from "next/dynamic";
import Image from "next/image";
import { FC, useState } from "react";
import useConstants from "../hooks/useConstants";
import imageNorthernLight from "../public/backgroundnorthernlight.png";
import Typography from "./utilities/Typography";

const Star = dynamic(() => import("./Star"), { ssr: false });

const NightSky: FC = () => {
  const { companyName, slogan } = useConstants();
  const [MousePosition, setMousePosition] = useState({
    x: "0",
    y: "0",
  });

  const windowSize = {
    x: typeof window !== "undefined" ? window.innerWidth : 1920,
    y: typeof window !== "undefined" ? window.innerHeight : 1080,
  };
  const randomize = (pos: number) => Math.random() * pos;
  const createElements = (num: number) => [...Array(num)];

  return (
    <>
      <div
        onMouseMove={(e) =>
          setMousePosition({
            x: `-${e.clientX / (window.innerWidth / 5)}%`,
            y: `-${e.clientY / (window.innerHeight / 5)}%`,
          })
        }
        className="relative flex min-h-[70vh] w-full items-center justify-center overflow-hidden after:pointer-events-none after:absolute after:bottom-0 after:border after:border-l-[50vw] after:border-r-[50vw] after:border-t-[7.5vw] after:border-white after:border-t-[transparent]"
      >
        <motion.span
          initial={false}
          animate={{
            x: MousePosition.x,
            y: MousePosition.y,
          }}
          transition={{ duration: 0 }}
          className="absolute top-0 bottom-0 left-0 right-0 h-full w-full"
        >
          {createElements(100).map((el, i) => (
            <Star
              key={i}
              scale={randomize(1)}
              delay={randomize(10)}
              xPos={randomize(windowSize.x)}
              yPos={randomize(windowSize.y) / 1.25}
            />
          ))}
        </motion.span>
        <motion.span
          initial={false}
          animate={{ x: MousePosition.x, y: MousePosition.y }}
          transition={{ duration: 0 }}
          className="absolute bottom-0 left-0 right-0 top-0 z-[-1] h-[120%] w-[120%] bg-primary"
        >
          <Image
            src={imageNorthernLight}
            alt={`achtergrond afbeelding ${companyName}`}
            quality={100}
            priority
            fill
            sizes="100vw"
            className="object-fill object-top"
          />
        </motion.span>

        <div className="z-40 px-4 py-16 text-center">
          <Typography variant="title" color="text-white">
            {companyName}
          </Typography>

          <Typography
            variant="subtitle"
            color="text-white"
            className="inner-text-size mt-8 border-t border-white pt-8"
            dangerouslySetInnerHTML={{
              __html: slogan.replace(
                "web",
                "<span class='text-5xl sm:text-6xl lg:text-7xl'>Web</span>"
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default NightSky;
