import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import { FC } from "react";
import Typography from "./utilities/Typography";

interface FeatureSectionProps {
  title: string;
  items: { icon: IconProp; title: string; description: string; href: string }[];
}

const FeatureSection: FC<FeatureSectionProps> = ({ title, items }) => {
  return (
    <div className="container my-16 text-center xl:max-w-6xl">
      <Typography variant="h2" color="text-primary" className="mb-4">
        {title}
      </Typography>

      <div className="mt-4 grid grid-cols-1 gap-8 sm:grid-cols-2 md:gap-8 lg:grid-cols-3">
        {items.map((item, index) => (
          <Link href={item.href} passHref key={index} legacyBehavior>
            <a className="relative mx-auto mt-[27.25px] max-w-xs rounded-xl bg-primary-light p-4 shadow-md hover:shadow focus:shadow focus:outline-none motion-safe:transition-shadow sm:max-w-none">
              <span className="absolute top-[-27.25px] left-1/2 flex h-[calc(1.25em+2rem)] -translate-x-1/2 items-center justify-center rounded-xl bg-blue p-4 text-lg text-white shadow">
                <FontAwesomeIcon icon={item.icon} fixedWidth />
              </span>
              <div className="mt-6">
                <Typography variant="h4" component="h3">
                  {item.title}
                </Typography>
                <Typography variant="paragraph" className="leading-snug">
                  {item.description}
                </Typography>
              </div>
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FeatureSection;
