import { NextSeo } from "next-seo";
import Image from "next/image";
import { ReactElement } from "react";
import Banner from "../components/Banner";
import FeatureSection from "../components/FeatureSection";
import NightSky from "../components/NightSky";
import Anchor from "../components/utilities/Anchor";
import Typography from "../components/utilities/Typography";
import useConstants from "../hooks/useConstants";
import DefaultLayout from "../layouts/DefaultLayout";
import GradientLayout from "../layouts/nested/GradientLayout";
import "../lib/fontawesome";
import imageProjects from "../public/projects-overview.png";
import { NextPageWithLayout } from "./_app";

const Home: NextPageWithLayout = () => {
  const { companyName, phone, email } = useConstants();

  return (
    <>
      <NextSeo
        description={`Een website op maat laten maken? Dat doe je bij ${companyName}. Snel, responsive en helemaal naar jouw wensen en stijl gebouwd. Kortom websites waarmee je met een gerust geweten kunt jagen op nieuwe klanten!`}
      />
      <NightSky />

      <GradientLayout>
        <div className="align-center container mt-12 mb-16 text-center lg:max-w-screen-md">
          <Typography variant="h2" color="text-primary" className="mb-4">
            Over ons
          </Typography>

          <Typography variant="paragraph">
            Wat wij doen voor onze klanten? State of the art websites bouwen op
            maat. Websites die er niet enkel fantastisch uitzien, maar ook nog
            eens super performant en vlot vindbaar zijn in de zoekmachines, op
            elk toestel tot hun recht komen en jouw onderneming op een passende
            manier in de kijker zetten. Websites die je zitten als het perfecte
            jasje en ook klanten weten te charmeren.
          </Typography>
          <Typography variant="paragraph">
            Kortom websites waarmee je met een gerust geweten kunt jagen op
            nieuwe klanten!
          </Typography>
          <Anchor
            href="/over-ons"
            className="text-lg"
            title={`Lees meer over ${companyName}`}
          >
            Lees meer over {companyName}
          </Anchor>
        </div>

        <FeatureSection
          title="Diensten"
          items={[
            {
              icon: "file-code",
              title: "Webdevelopment",
              description: `Wil je een website die scoort? Een website die makkelijk gevonden wordt, weet te overtuigen en uit de massa springt? Dan zit je goed bij ${companyName}.`,
              // "Wil je een website die scoort? Een website die makkelijk gevonden wordt, weet te overtuigen, voor bijkomende verkoop zorgt en je merk/diensten/producten boven de anderen uittilt? Dan zit je goed bij Arctic Webfox. Wij zorgen voor razendsnelle websites op maat van jouw doelgroep!",
              href: "/diensten/webdevelopment",
            },
            {
              icon: "bullhorn",
              title: "Marketing",
              description: `Een geweldige website is één ding, die in de markt zetten wat anders... Bij ${companyName} halen we met digitale marketing alles uit jouw website.`,
              // "Een geweldige website is één ding, om écht succes te boeken moet je deze ook in de markt zetten en ondersteunen met de nodige digitale marketing. Bij Arctic Webfox zijn we thuis in de kneepjes van dit vak en gaan we samen met jou resoluut voor rendement. Zo haal jij alle potentieel uit je website. ",
              href: "/diensten/marketing",
            },
            {
              icon: "pencil-ruler",
              title: "Design",
              description: `Het oog wil ook wat. Van webdesign tot een logo, complete huisstijl of ander grafisch ontwerp? ${companyName} zorgt voor een creatieve uitwerking.`,
              // "Een functionele website volgens de regels van de kunst is één ding, maar het oog wil ook wat. Onze designers zorgen niet enkel voor fraaie websites, ook jouw branding is bij ons in professionele handen. Een logo, complete huisstijl of ander grafisch ontwerp? Wij zorgen voor een creatieve uitwerking.",
              href: "/diensten/design",
            },
          ]}
        />

        <Banner
          title={`Neem contact op met ${companyName}!`}
          buttons={[
            {
              href: `mailto:${email}`,
              title: email,
            },
            {
              href: `tel:${phone.html}`,
              title: phone.default,
            },
          ]}
        />

        <div className="container grid justify-center pt-16 sm:grid-cols-2">
          <span className="">
            <Image src={imageProjects} alt="Projecten" />
          </span>
          <div className="inline-flex flex-col items-center justify-center space-y-4 text-center sm:items-start sm:text-left">
            <Typography variant="h2" color="text-primary">
              Projecten
            </Typography>
            <Typography variant="h4" component="h3" color="text-gray">
              Wil je weten wat we bij {companyName} kunnen?
            </Typography>
            <Anchor
              href="/projecten"
              className="inline-block text-lg sm:self-start"
            >
              Bekijk enkele van onze recente projecten!
            </Anchor>
          </div>
        </div>
      </GradientLayout>
    </>
  );
};

export default Home;

Home.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};
