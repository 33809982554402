import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ComponentProps, FC } from "react";
import useSocialMedia from "../hooks/useSocialMedia";
import Anchor from "./utilities/Anchor";
import Typography from "./utilities/Typography";

interface BannerProps extends ComponentProps<"div"> {
  title?: string;
  buttons: { href: string; title: string }[] | "socialMedia";
  variant?: "white" | "primary-light";
}

const Banner: FC<BannerProps> = ({
  title,
  buttons,
  variant = "white",
  className,
  children,
}) => {
  const socialMediaData = useSocialMedia();

  return (
    <div
      className={clsx(className, "rounded-xl bg-opacity-75 py-12 shadow-md", {
        "bg-white": variant === "white",
        "bg-primary-light": variant === "primary-light",
      })}
    >
      <div className="text-container text-center">
        {title && (
          <Typography
            variant="paragraph-primary"
            component="h2"
            className="mb-4 text-4xl font-extrabold sm:text-5xl"
            color="text-primary"
          >
            {title}
          </Typography>
        )}

        {children}

        {buttons instanceof Array &&
          buttons.map(({ href, title }, index) => (
            <Anchor
              href={href}
              key={index}
              variant="contained"
              className="mx-2 mb-4"
            >
              {title}
            </Anchor>
          ))}

        {buttons === "socialMedia" && (
          <div className="-mb-4">
            {Object.values(socialMediaData).map((social, key) => (
              <Anchor
                key={key}
                href={social.url}
                title={social.title}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                className="mx-2 mb-4"
              >
                <FontAwesomeIcon
                  icon={["fab", social.icon as IconName]}
                  fixedWidth
                  className="mr-2"
                />
                {social.title}
              </Anchor>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
